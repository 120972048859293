// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../src/pages/chi_siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-equipment-cucine-js": () => import("./../src/pages/equipmentCucine.js" /* webpackChunkName: "component---src-pages-equipment-cucine-js" */),
  "component---src-pages-facility-management-js": () => import("./../src/pages/facilityManagement.js" /* webpackChunkName: "component---src-pages-facility-management-js" */),
  "component---src-pages-galleria-js": () => import("./../src/pages/galleria.js" /* webpackChunkName: "component---src-pages-galleria-js" */),
  "component---src-pages-generic-js": () => import("./../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-impianti-elettrici-js": () => import("./../src/pages/impiantiElettrici.js" /* webpackChunkName: "component---src-pages-impianti-elettrici-js" */),
  "component---src-pages-index-dev-js": () => import("./../src/pages/index_dev.js" /* webpackChunkName: "component---src-pages-index-dev-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

